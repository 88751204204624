import React from "react";
import Grid from "@material-ui/core/Grid";
import renderHTML from "react-render-html";
import "./Assinatura.css";

class Assinatura extends React.Component {
  saveAndContinue = (e) => {
    e.preventDefault();
  };

  render() {
    const { href } = document.location;
    const forcedCache = new Date().getTime();

    const data = this.props.values;
    const html = `<table style="width: 304px; font-size: 9pt; font-family: 'Roboto', Arial, sans-serif; line-height:normal;" cellpadding="0"
    cellspacing="0">
        <style>
            @import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap');
        </style>
        <tbody>
            <tr>
                <td style="font-size:12px; font-family: 'Roboto', Arial, sans-serif; font-weight: bold; color: #000000; padding-bottom:10px;">
                <span>${data.nome}</span></td>
            </tr>
            <tr>
                <td style="font-size:12px; font-family: 'Roboto', Arial,sans-serif; font-weight: 400; color: #414042; padding-bottom:6px;">
                <span>${data.cargo}</span></td>
            </tr>
            <tr>
                <td style="font-size:12px; font-family: 'Roboto', Arial, sans-serif; font-weight: 400; color: #414042; padding-bottom:6px;">
                    <span>${data.telefone} | (43) 3026-1120</span>
                </td>
            </tr>
            <tr>
                <td style="font-size:12px; font-family: 'Roboto', Arial, sans-serif; font-weight: 400; color: #414042; padding-bottom:6px;">
                    <a href="https://www.leanwork.com.br" target="_blank" style="text-decoration: none; color: #414042;">www.leanwork.com.br</a>
                </td>
            </tr>
            <tr>
                <td style="font-size:12px; font-family: 'Roboto', Arial, sans-serif; font-weight: 400; color: #414042;">
                    <span>Siga-nos: 
                        <a href="https://www.instagram.com/leanworkgroup" target="_blank" style="text-decoration: none; color: #414042; font-weight: 400;">instagram</a>
                        |
                        <a href="https://www.linkedin.com/company/leanwork-tecnologia" target="_blank" style="text-decoration: none; color: #414042; font-weight: 400;">linkedin</a>
                        |
                        <a href="https://www.facebook.com/leanwork" target="_blank" style="text-decoration: none; color: #414042; font-weight: 400;">facebook</a>                        
                    </span>
                </td>
            </tr>
            <tr>
                <td style="width:304px; vertical-align:top;" valign="top">
                    <table style="padding: 16px 0; border-bottom: 1px solid #000000; width: 100%;">
                        <tbody>
                            <tr>
                                <td style="text-align: center;">
                                    <img border="0" alt="qr code leanwork" height="50" width="50" style="border:0" src="${href}assets/qrCodeLean.png?${forcedCache}">
                                </td>
                                <td style="text-align: center;">
                                    <a href="https://www.leanwork.com.br" target="_blank" style="text-decoration: none; border-right: 1px solid #000000; display: block; padding: 0 6px;">
                                        <img border="0" alt="logo leanwork group" style="border:0; max-width: auto; width: auto; height: auto; display: block; margin: 0 auto; max-width: 100px;" src="${href}assets/leanGroupLogo.png?${forcedCache}">
                                    </a>
                                </td>
                                <td style="text-align: center;">
                                    <a href="https://www.leancommerce.com.br" target="_blank" style="text-decoration: none; display: block; padding: 0 6px;">
                                        <img border="0" alt="logo lean commerce" style="border:0; max-width: auto; width: auto; height: auto; display: block; margin: 0 auto; max-width: 100px;" src="${href}assets/leanCommLogo.png?${forcedCache}">
                                    </a>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </td>                
            </tr>
            <tr>
                <td style="font-size:10px; font-family: 'Roboto', Arial, sans-serif; color: #414042; font-weight: 400; padding: 4px 0;">
                    <span>"Nascemos pelo propósito de fazer a diferença na vida das pessoas promovendo sempre uma experiência positiva."</span>
                </td>
            </tr>
        </tbody>
    </table>
    <p style="font-size:8px; max-width: 384px; font-family: 'Roboto', Arial, sans-serif; font-weight: 400; color: #c6c6c6; padding: 16px 0;">
        Esse e-mail e quaisquer documentos que o acompanham podem conter informações confidenciais ou legalmente protegidas contra divulgação. Caso você não seja o destinatário pretendido desta mensagem, por favor, alerte o remetente e exclua esta mensagem e seus possíveis anexos. Qualquer uso, propagação, divulgação ou algo semelhante relacionado ao conteúdo desta mensagem por qualquer pessoa que não seja o destinatário é rigorosamente proibida e suscetível a medidas legais para impedir quaisquer eventualidades.
    </p>
    `;

    return (
      <React.Fragment>
        {/* <Typography variant="h6" className="ml-auto" gutterBottom>
          Selecione -> Copie -> Cole na caixa de configuração de assinatura do
          seu email.
        </Typography> */}
        <Grid container spacing={24} style={{ margin: "0 -12px" }}>
          <Grid item xs={12} className="selecione">
            {renderHTML(html)}
          </Grid>
        </Grid>
      </React.Fragment>
    );
  }
}

export default Assinatura;
