import React from "react";
import Grid from "@material-ui/core/Grid";
import InputMask from "react-input-mask";
import TextField from "@material-ui/core/TextField";

class Dados extends React.Component {
  saveAndContinue = (e) => {
    e.preventDefault();
  };

  render() {
    const state = this.props.state;

    return (
      <React.Fragment>
        <Grid container spacing={24}>
          <Grid item xs={12}>
            <TextField
              required
              id="nome"
              name="nome"
              label="Nome Completo"
              fullWidth
              autoComplete="fnome"
              value={state.nome}
              onChange={this.props.handleChange}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              required
              id="cargo"
              name="cargo"
              label="Função"
              fullWidth
              autoComplete="fcargo"
              value={state.cargo}
              onChange={this.props.handleChange}
            />
          </Grid>
          {/* <Grid item xs={12}>
            <TextField
              required
              id="email"
              name="email"
              label="E-mail"
              placeholder="seuemail@leanwork.com.br"
              fullWidth
              autoComplete="femail"
              value={state.email}
              onChange={this.props.handleChange}
            />
          </Grid> */}
          <Grid item xs={12} sm={6}>
            <InputMask
              mask="+99 (99) 99999-9999"
              maskChar=" "
              onChange={this.props.handleChange}
              value={state.telefone}
            >
              {() => (
                <TextField
                  required
                  id="telefone"
                  name="telefone"
                  label="Telefone"
                  fullWidth
                  autoComplete="ftelefone"
                />
              )}
            </InputMask>
          </Grid>
        </Grid>
      </React.Fragment>
    );
  }
}

export default Dados;
