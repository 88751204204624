import React from "react";
import PropTypes from "prop-types";
import withStyles from "@material-ui/core/styles/withStyles";
import CssBaseline from "@material-ui/core/CssBaseline";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Dados from "./Dados";
import Assinatura from "./Assinatura";

const styles = (theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    minHeight: "100vh",

    background: "url(/assets/banner.jpg) no-repeat",
    backgroundSize: "cover",
    backgroundPosition: "center center",
  },
  title: {
    marginBottom: "12px",
    fontWeight: "700",
    fontSize: "26px",
    fontFamily: "Leanwork",
  },
  appBar: {
    position: "relative",
    height: "140px",
    overflow: "hidden",
    background: "transparent",
    boxShadow: "none",
  },
  toolBar: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "140px",
  },
  logo: {
    display: "block",
    maxWidth: "100%",
    height: "auto",
  },
  layout: {
    flex: "1 1 auto",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    maxWidth: "600px",

    marginLeft: theme.spacing.unit * 2,
    marginRight: theme.spacing.unit * 2,
    [theme.breakpoints.up(600 + theme.spacing.unit * 2 * 2)]: {
      width: "100%",
      marginLeft: "auto",
      marginRight: "auto",
    },
  },
  paper: {
    marginTop: theme.spacing.unit * 3,
    marginBottom: theme.spacing.unit * 3,
    padding: theme.spacing.unit * 2,
    width: "100%",
    maxWidth: "550px",
    borderRadius: "12px",

    [theme.breakpoints.up(600 + theme.spacing.unit * 3 * 2)]: {
      marginTop: theme.spacing.unit * 6,
      marginBottom: theme.spacing.unit * 6,
      padding: theme.spacing.unit * 3,
    },
  },
  stepper: {
    padding: `${theme.spacing.unit * 3}px 0 ${theme.spacing.unit * 5}px`,
  },
  buttons: {
    display: "flex",
    justifyContent: "space-around",
    alignItems: "center",
    marginTop: "48px",
    marginBottom: "20px",
  },
  button: {
    background: "#000",
    padding: "12px 16px",
    color: "#fff",

    "&:hover": {
      background: "#fcfcfc",
      color: "#000",
    },
  },
  buttonBack: {
    background: "#fff",
    padding: "12px 16px",
    color: "#000000",
    minHeight: "unset",
  },
  footer: {
    marginTop: theme.spacing.unit * 3,
  },
});

class Index extends React.Component {
  state = {
    activeStep: 0,
    stepBtnText: "Gerar Assinatura",
    nome: "",
    cargo: "",
    email: "",
    telefone: "+55",
  };

  handleChange(event) {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    this.setState({
      [name]: value,
    });
  }

  validaStep() {
    if (this.state.activeStep === 0) {
      return (
        this.state.nome !== null &&
        this.state.cargo !== null &&
        this.state.email !== null &&
        this.state.telefone !== null
      );
    }
    return true;
  }

  handleNext = () => {
    if (this.validaStep()) {
      this.setState((state) => ({
        activeStep: state.activeStep + 1,
        stepBtnText: "Copiar",
      }));
    }
  };

  handleBack = () => {
    this.setState((state) => ({
      activeStep: state.activeStep - 1,
      stepBtnText: "Gerar Assinatura",
    }));
  };

  handleReset = () => {
    this.setState({
      activeStep: 0,
    });
  };

  getStepContent = (step) => {
    switch (step) {
      case 0:
        return <Dados state={this.state} handleChange={this.handleChange.bind(this)} classes={this.props.classes} />;
      // case 1:
      //   return <Sociais state={this.state} handleChange={this.handleChange.bind(this)}/>;
      case 1:
        return <Assinatura values={this.state} />;
      default:
        throw new Error("Unknown step");
    }
  };

  copySignature = () => {
    const selBox = document.createElement("input");
    const signatureHtml = document.getElementsByClassName("selecione")[0].innerHTML;
    selBox.value = signatureHtml;

    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();

    const listener = (e) => {
      e.clipboardData.setData("text/html", signatureHtml);
      e.clipboardData.setData("text/plain", signatureHtml);
      e.preventDefault();
    };

    document.addEventListener("copy", listener);
    document.execCommand("copy");
    document.removeEventListener("copy", listener);

    document.body.removeChild(selBox);
    this.setState((state) => ({
      stepBtnText: "Copiado",
    }));

    setTimeout(() => {
      this.setState((state) => ({
        stepBtnText: "Copiar",
      }));
    }, 1000);
  };

  render() {
    const { classes } = this.props;
    const { activeStep, stepBtnText } = this.state;
    return (
      <div className={classes.root}>
        <CssBaseline />
        <AppBar position="absolute" color="default" className={classes.appBar}>
          <Toolbar className={classes.toolBar}>
            <img src="/assets/logo.png" className={classes.logo} alt="Logo" />
          </Toolbar>
        </AppBar>
        <main className={classes.layout}>
          <Paper className={classes.paper}>
            <Typography component="h2" variant="h4" align="center" className={classes.title}>
              Gerar assinatura de e-mail
            </Typography>
            <React.Fragment>
              {this.getStepContent(activeStep)}
              <div className={classes.buttons}>
                {activeStep !== 0 && (
                  <Button type="submit" onClick={this.handleBack} className={classes.buttonBack}>
                    Voltar
                  </Button>
                )}
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => (activeStep === 0 ? this.handleNext() : this.copySignature())}
                  className={classes.button}
                  align="center"
                >
                  {stepBtnText}
                </Button>
              </div>
            </React.Fragment>
          </Paper>
        </main>
      </div>
    );
  }
}

Index.propTypes = {
  classes: PropTypes.object.isRequired,
  state: PropTypes.object,
};

export default withStyles(styles)(Index);
